<template>
  <b-modal
      id="modal-scrollable"
      ref="my-modal"
      size="lg"
      hide-footer
      title="Exams Setup"
  >
    <v-app>
              <div class="row mt-5">
                <div class="col-12">
                      <v-row>
                        <div class="col-12">
                          <v-text-field outlined dense v-model="exam.title"
                                        placeholder="Exam Title (E.g. First Terminal)"></v-text-field>
                          <span class="text-danger"
                                v-if="$v.exam.title.$error">Title is required</span>
                        </div>
                        <div class="col-3">
                          <v-select
                              :items="types"
                              v-model="exam.type"
                              outlined
                              dense
                              item-text="text"
                              item-value="value"
                              label="Type"
                          >
                          </v-select>
                          <span class="text-danger"
                                v-if="$v.exam.type.$error">Type is required</span>
                        </div>
                        <div class="col-3">
                          <v-select
                              :items="academicYears"
                              v-model="exam.academic_year_id"
                              outlined
                              dense
                              item-text="year"
                              item-value="id"
                              label="Academic Year"
                          >
                          </v-select>
                          <span class="text-danger" v-if="$v.exam.academic_year_id.$error">Academic Year is required</span>
                        </div>
                        <div class="col-3">
                          <v-select
                              :items="levels"
                              v-model="exam.level_id"
                              outlined
                              dense
                              item-text="title"
                              item-value="id"
                              label="Level"
                              @change="getPrograms"
                          >

                          </v-select>
                          <span class="text-danger" v-if="$v.exam.level_id.$error">Level is required</span>
                        </div>
                        <div class="col-3">
                          <v-select
                              :items="programs"
                              v-model="exam.program_id"
                              outlined
                              dense
                              label="Programs"
                              item-text="title"
                              item-value="id"
                              @change="getGrades()"
                          >

                          </v-select>
                          <span class="text-danger" v-if="$v.exam.program_id.$error">Program is required</span>
                        </div>
                        <div class="col-3">
                          <v-select
                              :items="gradesLevels"
                              v-model="exam.grade_id"
                              outlined
                              dense
                              label="Semester/Year"
                              item-text="title"
                              item-value="id"
                          >

                          </v-select>
                          <span class="text-danger" v-if="$v.exam.grade_id.$error">Grade is required</span>
                        </div>
                        <div class="col-3">
                          <v-text-field
                              type="number"
                              v-model="exam.duration"
                              outlined
                              dense
                              label="Duration"
                          >

                          </v-text-field>
                          <span class="text-danger" v-if="$v.exam.duration.$error">Duration is required</span>
                        </div>
                        <div class="col-3">
                          <v-select
                              :items="durations_units"
                              v-model="exam.duration_unit"
                              outlined
                              dense
                              label=" Duration Unit"
                              item-text="title"
                              item-value="id"
                          >

                          </v-select>
                          <span class="text-danger" v-if="$v.exam.program_id.$error">Program is required</span>
                        </div>
                        <div class="col-3">
                          <v-select
                              :items="locations"
                              v-model="exam.exam_location"
                              outlined
                              dense
                              label=" Location"
                              item-text="text"
                              item-value="value"
                          >

                          </v-select>
                          <span class="text-danger" v-if="$v.exam.exam_location.$error">Exam Location is required</span>
                        </div>
                        <div class="col-6">
                          <v-menu
                              ref="menu"
                              v-model="menu"
                              :close-on-content-click="false"
                              :return-value.sync="date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="exam.start_date"
                                  label="Start Date"
                                  prepend-inner-icon="mdi-calendar"
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="exam.start_date"
                                no-title
                                scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="menu = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.menu.save(date)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                          <span class="text-danger" v-if="$v.exam.start_date.$error">Start Date is required</span>
                        </div>
                        <div class="col-6">
                          <v-menu
                              ref="menu2"
                              v-model="menu2"
                              :close-on-content-click="false"
                              :return-value.sync="date"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="exam.end_date"
                                  label="End Date"
                                  prepend-inner-icon="mdi-calendar"
                                  outlined
                                  dense
                                  v-bind="attrs"
                                  v-on="on"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="exam.end_date"
                                no-title
                                scrollable
                            >
                              <v-spacer></v-spacer>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="menu2 = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                  text
                                  color="primary"
                                  @click="$refs.menu.save(date)"
                              >
                                OK
                              </v-btn>
                            </v-date-picker>
                          </v-menu>
                        </div>
                        <div class="col-12">
                          <v-textarea
                              v-model="exam.location_description"
                              outlined
                              dense
                              placeholder="Enter Room No or address of the exam"
                              label=" Location Description"
                          >

                          </v-textarea>
                        </div>
                        <div class="col-3">
                          <v-switch
                              v-model="exam.is_active"
                              label="Status"
                          >

                          </v-switch>
                        </div>
                        <div class="col-12 text-right mt-4">
                          <v-btn
                                  color="red"
                                  class="btn btn-standard"
                                  depressed
                                  @click="resetForm"
                          >Cancel
                          </v-btn>
                          <v-btn
                                  class="text-white ml-2 btn btn-primary"
                                  depressed
                                  @click="createAndUpdate"
                                  :loading="isBusy"
                          >Save
                          </v-btn>

                        </div>
                      </v-row>
<!--                    </v-tab-item>-->
<!--                    <v-tab-item class="mt-5">-->
<!--                      <v-multiselect-listbox :options="['Software Engineer', 'Java']"></v-multiselect-listbox>-->
<!--                    </v-tab-item>-->
<!--                  </v-tabs>-->
                </div>
              </div>
<!--            </div>-->
<!--          </div>-->
    </v-app>
  </b-modal>
</template>
<script>
import vMultiselectListbox from 'vue-multiselect-listbox'
import {required} from "vuelidate/lib/validators";
import "vue-multiselect-listbox/src/scss/vue-multi-select-listbox.scss";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import LevelService from "@/core/services/level/LevelService";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import ExamService from "@/core/services/exam/ExamService";

const levelService = new LevelService()
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const examService = new ExamService();

export default {
  name: "CreateOrUpdateExams",
  validations: {
    exam: {
      title: {required},
      type: {required},
      academic_year_id: {required},
      level_id: {required},
      program_id: {required},
      grade_id: {required},
      start_date: {required},
      duration: {required},
      duration_unit: {required},
      exam_location: {required},
    }
  },
  components: {

  },
  data() {
    return {
      exam: {
        title: null,
        type: 'terminal',
        academic_year_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        duration: null,
        duration_unit: 'hours',
        exam_location: 'in-premise',
        location_description: null,
        is_active: true
      },
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      isBusy: false,
      edit: false,
      menu2: false,
      levels: [],
      programs: [],
      academicYears: [],
      gradesLevels: [],
      types: [
        {
          text: 'Terminal',
          value: 'terminal',
        },
        {
          text: 'Class Test',
          value: 'class_test',
        },
        {
          text: 'Board',
          value: 'board',
        },
      ], locations: [
        {
          text: 'Online',
          value: 'online',
        },
        {
          text: 'In Premise',
          value: 'in-premise',
        },
        {
          text: 'Off Premise',
          value: 'off-premise',
        },
      ],
      durations_units: [
        'minutes', 'hours', 'days', 'months'
      ],
      academic_id: null,
      page: null,
      total: null,
      perPage: null,
      tab: null,
    };
  },
  mounted() {
    this.getLevels();
    this.getAcademicYears();
  },
  methods: {
    showModal(exam = {}) {
      if(exam.id){

        this.edit = true
        this.exam = exam
        this.getPrograms()
      }
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.teacher = []
      this.$refs["my-modal"].hide();
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    getLevels() {
      levelService.all().then(response => {
        this.levels = response.data;

      });
    },
    getPrograms() {
      programService.getAllByLevelId(this.exam.level_id).then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.paginate().then(response => {
        this.academicYears = response.data.data;

      });
    },
    getGrades() {
      gradeLevelService.getByProgram(this.exam.program_id).then(response => {
        this.gradesLevels = response.data;
      });
    },

    createAndUpdate() {

      this.$v.$touch();
      if (this.$v.exam.$error) {

        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        if (this.edit) {
          this.updateExam();
        } else {

          this.createExam();
        }
      }
    },
    createExam() {
      this.isBusy = true;
      examService.store(this.exam).then(response => {
        this.isBusy = false;
        this.resetForm()
        this.$snotify.success("Information added");
      });
    },
    updateExam() {
      this.isBusy = true;
      examService.update(this.exam.id, this.exam).then(response => {
        this.isBusy = false;
        this.resetForm()
        this.$snotify.success("Information updated");

      });
    }, resetForm() {
      this.exam = {
        title: null,
        type: null,
        academic_id: null,
        level_id: null,
        program_id: null,
        grade_id: null,
        start_date: null,
        end_date: null,
        duration: null,
        duration_unit: null,
        exam_location: null,
        location_description: null,
        is_active: true
      }
      this.$v.$reset()
      this.hideModal()
      this.$emit('refresh')
    }
  }
};
</script>
<style>
  .v-application--wrap {
    min-height: 0 !important;
  }
</style>
