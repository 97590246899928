<template>
  <v-app>
    <div class="card card-custom">
      <div class="card-body p-0">
        <div
            class="wizard wizard-2"
            id="kt_wizard_v2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
        >
          <div class="wizard-body py-8 px-8 py-lg-10 px-lg-10">
            <div class="row">
              <div class="col-md-6 col-lg-6 col-sm-12">
                <h4>Manage Results</h4>
              </div>
<!--              <div class="col-md-6 col-lg-6 col-sm-12 text-right mb-3" v-if="isAccessible('admin,exam-manager')">-->
<!--                <button @click="openDialog()" class="btn btn-primary ml-2">-->
<!--                 -->
<!--                  Add-->
<!--                </button>-->
<!--              </div>-->
              <div class="row mb-5 bg-filter">
                <div class="col-md-2">

                  <v-select
                      class="form-control"
                      outlined
                      v-model="search.academic_year"
                      dense
                      :items="academicYears"
                      item-text="title"
                      item-value="id"
                      label="Batch"
                  >
                  </v-select>
                </div>
                <div class="col-md-2">

                  <v-select
                      class="form-control"
                      outlined
                      :items="programs"
                      v-model="search.program_id"
                      @change="getGrades"
                      dense
                      item-text="title"
                      item-value="id"
                      label="Program"
                  >
                  </v-select>

                </div>
                <div class="col-md-2" v-show="search.program_id">

                  <v-select
                      class="form-control"
                      outlined
                      :items="gradesLevels"
                      v-model="search.grade_id"
                      dense
                      item-text="title"
                      item-value="id"
                      label="Semester/Year"
                  >
                  </v-select>
                </div>
                <div class="col-md-2">

                  <v-select
                      class="form-control"
                      outlined
                      :items="types"
                      v-model="search.type"
                      dense
                      item-text="text"
                      item-value="value"
                      label="Type"
                  >
                  </v-select>
                </div>
                <div class="col-md-2">
                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="search.start_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="search.start_date"
                          label="Search by date"
                          outlined dense
                          persistent-hint
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="search.start_date"
                        no-title
                        @input="menu2 = false"
                        range
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                          text
                          color="primary"
                          @click="menu2 = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(search.start_date)"
                      >
                        OK
                      </v-btn>

                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="col-md-2">
                  <v-select
                      class="form-control"
                      outlined
                      :items="statuses"
                      v-model="search.status"
                      dense
                      item-text="text"
                      item-value="value"
                      label="Status"
                  >
                  </v-select>
                </div>
                <!--                                <div class="col-md-2">-->
                <!--                                    <label>Mark Obtain-->
                <!--                                    </label>-->
                <!--                                    <v-text-field-->
                <!--                                            class="form-control"-->
                <!--                                            outlined-->
                <!--                                            v-model="search.markObtain"-->
                <!--                                            dense-->
                <!--                                            label="Mark obtained"-->
                <!--                                    >-->
                <!--                                    </v-text-field>-->
                <!--                                </div>-->
                <div class="col-2  my-auto">
                  <v-btn :loading="isBusy" depressed  class="btn btn-primary text-white" @click="getExams">Search</v-btn>
                  <button class="btn btn-secondary ml-2" @click="reset">Reset</button>
                </div>
              </div>

              <div class="col-12">
                <div class="">
                  <table class="table table-stripe">
                    <thead>
<!--                    <th>ID</th>-->
                    <th>Date</th>
                    <th>Title</th>
                    <th>Academic Year</th>
                    <th>Program</th>
                    <th>Semester/year</th>
                    <th>Total Student</th>
<!--                    <th>Duration</th>-->
                    <th>Exam Status</th>
                    <th>Result Status</th>
                    <th>Action</th>
                    </thead>
                    <tbody v-if="exams && exams.length>0">
                    <tr v-for="(exam, index) of exams" :key="index">
<!--                      <td>{{ exam.id }}</td>-->
                      <td><span class="badge badge-info"> {{ exam.start_date }}  to {{ exam.end_date ?  exam.end_date : 'N/A' }}</span></td>
                      <td>{{ exam.title }}</td>
                      <td><span class="badge badge-warning"> {{ exam.academic_year_title }}</span></td>
                      <td>{{ exam.program_title }}</td>
                      <td>{{ exam.semester_title }}</td>
                      <td><span class="badge badge-primary btn-circle">{{ exam.total_student }}</span></td>
                      <td>
                        <span class="badge"
                              :class="exam.exam_status ? exam.exam_status =='completed'?'badge-success':'badge-warning' :'badge-warning'"
                        >{{
                            exam.exam_status ? exam.exam_status.toUpperCase() : 'Not Completed'
                          }}</span>

                        <!--                                              Completed, Upcoming-->
                      </td>
                      <td>
                           <span class="badge"
                                 :class="exam.result_status ? exam.result_status =='published'?'badge-success':'badge-danger' :'badge-danger'"
                           >{{
                               exam.result_status ? exam.result_status.toUpperCase() : "Pending"
                             }}</span>
                        <br>
                        <span class="badge mt-2"
                              :class="exam.is_total_calculated ?'badge-primary':'badge-warning'"
                        >{{
                            exam.is_total_calculated ? 'Total Marks Calculated' : "Pending Marks Calculation"
                          }}</span>


                        <!--                                                     Published, Pending-->
                      </td>
                      <td>
                        <div class="kt-widget__toolbar">

                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown"
                              no-caret
                              right
                              no-flip
                              text="Actions"

                          >
                            <template v-slot:button-content>
                              <a href="#" class="" data-toggle="dropdown">
                                <!--              <span class="svg-icon svg-icon-success svg-icon-2x">-->
                                <!--begin::Svg Icon-->
                                <i class="flaticon-more-1"></i>
                                <!--end::Svg Icon-->
                                <!--              </span>-->
                              </a>
                            </template>
                            <div class="navi navi-hover">
                              <b-dropdown-text tag="div" class="navi-item"
                                               v-if="isAccessible('admin,  coordinator, exam-coordinator')">
                                <a href="#" class="navi-link" @click.prevent="calculateTotal(exam)">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-check"></i>
                                                                    </span>
                                  <span class="navi-text">  {{ exam.is_total_calculated ? 'Re-Calculate' : 'Calculate' }} Total</span>
                                </a>
                              </b-dropdown-text>         <b-dropdown-text tag="div" class="navi-item"
                                               v-if="exam.exam_status != 'completed' && isAccessible('admin,  coordinator, exam-coordinator')">
                                <a href="#" class="navi-link" @click.prevent="markAs(exam.id, 'completed')">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-check"></i>
                                                                    </span>
                                  <span class="navi-text">Mark as Complete</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item"
                                               v-if="exam.exam_status != 'completed'&& isAccessible('admin,  coordinator, exam-coordinator')">
                                <a href="#" class="navi-link" @click.prevent="markAs(exam.id, 'cancelled')">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-window-close"></i>
                                                                    </span>
                                  <span class="navi-text">Mark as Cancelled</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item"
                                               v-if="exam.result_status != 'published'&& isAccessible('admin,  coordinator, exam-coordinator')">
                                <a href="#" class="navi-link" @click.prevent="markAs(exam.id, 'published')">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-user-check"></i>
                                                                    </span>
                                  <span class="navi-text">   Mark as Result Published</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item"
                                               v-if="exam.result_status == 'published'&& isAccessible('admin,  coordinator, exam-coordinator')">
                                <a href="#" class="navi-link" @click.prevent="markAs(exam.id, 'unpublished')">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-user-check"></i>
                                                                    </span>
                                  <span class="navi-text">   Mark as Result UnPublished</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="isAccessible('admin,  coordinator, exam-coordinator, teacher')">
                                <router-link :to="{name:'results-view-all-courses', params:{slug:exam.slug}}"
                                             class="navi-link">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-book-reader"></i>
                                                                    </span>
                                  <span class="navi-text">Result By Course</span>
                                </router-link>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item"  v-if="isAccessible('admin,  coordinator, exam-coordinator')">
                                <router-link
                                    :to="{name:'results-view-all-courses-summary-students',params:{'examId':exam.id}}"
                                    class="navi-link">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-user-tie"></i>
                                                                    </span>
                                  <span class="navi-text">Overall Student Result</span>
                                </router-link>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="isAccessible('admin,  coordinator, exam-coordinator')">
                                <span @click="openImportResult(exam.id)"
                                      class="navi-link">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-file-csv"></i>
                                                                    </span>
                                  <span class="navi-text">Import Result</span>
                                </span>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="isAccessible('admin,  coordinator, exam-coordinator')">
                                <span @click="downloadLedger(exam.id)"
                                      class="navi-link">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-file-csv"></i>
                                                                    </span>
                                  <span class="navi-text">Download Ledger</span>
                                </span>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item" v-if="exam.result_status == 'published' && isAccessible('admin,  coordinator, exam-coordinator')">
                                <span @click="notifyStudents(exam.id)"
                                      class="navi-link">
                                                                    <span class="navi-icon">
                                                                      <i class="fa fa-bell"></i>
                                                                    </span>
                                  <span class="navi-text">Notify Students</span>
                                </span>
                              </b-dropdown-text>


                            </div>
                            <!--end::Navigation-->


                            <!--end::Navigation-->
                          </b-dropdown>
                        </div>

                      </td>
                    </tr>
                    </tbody>
                    <tbody v-else>
                    <tr>
                      <td colspan="5"></td>
                      <td colspan="10">
                        <h5>No data available to display.</h5>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="row" v-if="exams.length>0">
                <div class="col-12 text-center">
                  <b-pagination
                      @input="getExams"
                      v-model="page"
                      :total-rows="total"
                      :per-page="perPage"
                      first-number
                      last-number
                  ></b-pagination>
                  <!--  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <create-and-update ref="exam" @refresh="getExams"></create-and-update>
      <assign-courses ref="assignCourse" @refresh="getExams"></assign-courses>
    </div>
    <v-dialog
        v-model="dialog"
        width="900"
        title="Manage Result"
    >
      <v-card>
        <v-card-title>
          Import Result
        </v-card-title>
        <v-card-text>
          <div class="row">
            <div class="col-12">
              <v-file-input outlined dense v-model="excel_file"
                            accept=".xlsx, .xls, .csv"
                            label="File input"
              ></v-file-input>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn :loading="isLoading"
                 color="blue darken-1"
                 text
                 @click="importResult"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateAndUpdate";
import AcademicClassService from "@/core/services/academic-class/AcademicClassService";
import ExamService from "@/core/services/exam/ExamService";
import AssignCourses from "./AssignCourses";
import ProgramService from "@/core/services/level/program/ProgramService";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";
import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
import ConfigService from "../../../core/services/config/ConfigService";
import { API_URL} from "@/core/config";


const examService = new ExamService();
const academicClassService = new AcademicClassService();
const programService = new ProgramService();
const academicYearService = new AcademicYearService();
const gradeLevelService = new GradeLevelService();
const configService = new ConfigService();

export default {
  name: "academic-class",
  components: {
    CreateAndUpdate,
    AssignCourses
  },
  data() {
    return {
      menu2:false,
      menu:false,
      statuses: [
        {
          text: 'All',
          value: 'all',
        }, {
          text: 'Upcoming',
          value: 'upcoming',
        },
        {
          text: 'Past',
          value: 'past',
        },
        {
          text: 'Exam Completed',
          value: 'completed',
        },
        {
          text: 'Exam Cancelled',
          value: 'cancelled',
        },
        {
          text: 'Exam Terminated',
          value: 'terminated',
        },
        {
          text: 'Exam Taken',
          value: 'taken',
        },
        {
          text: 'Result Publish',
          value: 'publish',
        },
        {
          text: 'Result Pending',
          value: 'pending',
        },

      ],
      dialog: false,
      isLoading: false,
      isBusy: false,
      examId: null,
      excel_file: null,
      academic_classes: [],
      exams: [],
      search: {
        academic_year: "",
        program_id: "",
        grade_id: "",
        type: "",
        status: "all",
        start_date: [],
      },
      academicYears: [],
      programs: [],
      gradesLevels: [],
      types: [
        {
          text: 'Terminal',
          value: 'terminal',
        },
        {
          text: 'Class Test',
          value: 'class_test',
        },
        {
          text: 'Board',
          value: 'board',
        },
      ],
      academic_id: null,
      page: null,
      total: null,
      perPage: null
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  mounted() {
    // this.getAcademicClasses();
    this.getExams();
    this.getAcademicYears();
    this.getPrograms();
    this.getGrades();
  },
  methods: {
    isAccessible(roles) {
      let flag = [];
      let type = this.currentUser.type;
      let userRoles = configService.getRoles(type);
      if(roles!=null && roles!=undefined && userRoles!=null && userRoles!=undefined){
        roles = roles.split(",");
        for (let t of roles) {
          t = t.trim();
          if (userRoles.includes(t)) {
            flag.push(true);
          } else {
            flag.push(false);
          }
        }
        if (flag.includes(true)) {
          return true;
        }
      }
      return false;
    },
    getGrades() {
      gradeLevelService.getByProgram(this.search.program_id).then(response => {
        this.gradesLevels = response.data;
      });
    },
    calculateTotal(exam) {
      exam.calculate_total = 1;
      examService.update(exam.id, exam).then(response => {
        this.$snotify.success("Total calculation has been scheduled.");
        this.getExam();
      });
    },
    getPrograms() {
      programService.all().then(response => {
        this.programs = response.data;
      });
    },
    getAcademicYears() {
      academicYearService.paginate().then(response => {
        this.academicYears = response.data.data;

      });
    },
    reset() {
      this.search = {
        academic_year: "",
        program_id: "",
        grade_id: "",
        type: "",
        status: "all",
        start_date: [],
      }

      this.getExams();
    },
    getAcademicClasses() {
      academicClassService.paginate().then(response => {
        this.exams = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },
    getExams() {
      this.isBusy = true;
      this.$bus.emit('toggleLoader');
      examService.paginate(this.search).then(response => {
        this.exams = response.data.data;
        // console.log(this.exams)
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      }).catch((err) => {
        // console.log(err)
      }).finally(() => {
        this.isBusy = false;
        this.$bus.emit('toggleLoader');
      });
    },
    openDialog(exam) {
      this.$refs["exam"].showModal(exam);
    },


    assignCourse(exam) {
      this.$refs["assignCourse"].showModal(exam);
    },

    markAs(id, type) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            examService.markAs(id, type).then(response => {
              this.$snotify.success("Information updated");
              this.getExams();
            });
          }
        }
      });
    },

    deleteAcademicYear(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            academicClassService.delete(id).then(response => {
              this.$snotify.success("Information deleted");
              this.getAcademicClasses();
            });
          }
        }
      });
    }, openImportResult(id) {
      this.dialog = true
      this.examId = id;

    },
    importResult() {
      this.isLoading = true
      let fd = new FormData();
      fd.append("file", this.excel_file);
      examService.importResult(this.examId, fd).then(response => {
        this.isLoading = false
        this.excel_file = null;
        this.$snotify.success("Information imported")
        this.examId = null;
        this.dialog = false
        this.getExams();
      })
    }, notifyStudents(id) {
      this.$confirm({
        message: `Are you sure?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        callback: confirm => {
          if (confirm) {
            examService.notifyStudents(id).then(response => {
              this.$snotify.success("Information updated")
            })
          }
        }
      });
    },
    downloadLedger(examId) {
      window.open(
          API_URL + "download/exam/ledger/" + examId,
          "_blank"
      );
    },
  }
};
</script>
